import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { TechSection, Android, iOS, Flutter, Mobile, Swift, Kotlin, Java, Python, Go, AndroidEMM, SoC, BigData, Linux, BigQuery, TensorFlow, Docker, BLE, Blockchain, AWSIoT } from '../../components/icons/Technologies'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import Analytics from '../../components/analytics'

const IndustriesPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO title="Industries" pageUrl="/industries/" description="Tumba Solutions has more than 20 years of experience in different industry domains, such as news publishing, entertainment, cycling, autonomous driving and urban mobility." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6 mb-72 sm:h-48">
        <HeaderLink className="text-primary-2" />
      </Header>
      <BackgroundWithGrid image="industries-hero" gridRow={1} />
      <Deco decoType={2} inner after gridRow={1} />
      <Section gridRow={2}>
        <h1 className="text-left text-primary-1 text-4xl lg:text-5xl">Industries</h1>
      </Section>
      <Section className="z-20 pt-12 pb-48 grid gap-12 grid-cols-6 lg:grid-cols-12" gridRow={3}>
        <div className="col-span-6 text-xl lg:text-2xl">
          <p>
            Tumba Solutions is a trusted software development partner for scale-ups, SMEs, and enterprise businesses worldwide, with over 20 years of experience across various industry sectors. Our most notable engagements include work in digital publishing,
            entertainment, active mobility, health, sports, and autonomous technologies.
          </p>
          <p className="pt-8">Company involvements vary from designing and developing mobile applications and MVPs to building distributed systems at scale while transforming existing products with cutting edge technologies and concepts.</p>
        </div>
        <div className="col-span-5">
          <p>At Tumba we keep it simple - we adjust to your agile work process, provide predictable cost and ensure sustainable and unmatched product growth. Internally we follow the OKR methodology.</p>
          <p className="pt-6">We keep our teams small and focused, composed of senior software generalists with the right mix of roles and responsibilities to get the job done right.</p>
        </div>
        <div className="col-span-6">
          <h2 className="col-span-5 text-2xl lg:text-3xl">Key tech & tools</h2>
          <div className='mt-10 grid gap-x-4 gap-y-8 grid-cols-3'>
            <TechSection Icon={Mobile} rotate={0} text='Mobile' />
            <TechSection Icon={AndroidEMM} rotate={0} text='Android EMM' />
            <TechSection Icon={AWSIoT} rotate={0} text='IoT' />
            <TechSection Icon={BigData} rotate={90} text='Big Data' />
            <TechSection Icon={Blockchain} rotate={90} text='Blockchain' />
            <TechSection Icon={BLE} rotate={-75} text='Bluetooth LE' />
            <TechSection Icon={SoC} rotate={-75} text='System on a Chip' />
            <TechSection Icon={Linux} rotate={0} text='Linux' />
            <TechSection Icon={Docker} rotate={0} text='Docker' />
          </div>
        </div>
        <div className='col-span-5'>
        <h2 className="text-2xl lg:text-3xl">Products</h2>
          <p className="pt-10">We developed an in-house product - an automated device provisioning to dedicated devices or kiosks. We are working on a heart training technology built with user privacy in mind.</p>
          <p className="pt-6">We are open for partnerships under H2020 and looking for investment opportunities.</p>
        </div>
        <div className="col-span-5 lg:col-span-6">
          <h2 id="auto" className="text-2xl lg:text-3xl">
            AutoTech
          </h2>
          <p className="pt-10">When cutting edge technology meets Automotive industry, we are the right partner. We have the start-up mindset, the consultant expertise and the business drive for results.</p>
        </div>
        <div className="col-span-5">
          <h2 id="smart-city" className="text-2xl lg:text-3xl">
            Digital City
          </h2>
          <p className="pt-10">Revolutionizing urban planning and mobility with Unreal Engine 5-based simulations. Explore lifelike scenarios and tech-driven solutions for complex urban environments.</p>
        </div>
        <div className="col-span-5 lg:col-span-6">
          <h2 id="media" className="text-2xl lg:text-3xl">
            Digital Media
          </h2>
          <p className="pt-10">
            We are seasoned devs in the news publishing, media and entertainment industries. We do holistic mobile solutions, not just apps. Want to see your business at the technology edge?{' '}
            <Link to="/meet-us" className="text-primary-1">
              Ping us
            </Link>
            .
          </p>
        </div>
        <div className="col-span-5">
          <h2 id="health" className="text-2xl lg:text-3xl">
            Digital Health
          </h2>
          <p className="pt-10">We head the software development of the top-performance & smartest indoor trainer, adopted by world-class athletes, army and dedicated cyclists. Engineering sport metrics in data science is our thing.</p>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={4} />
      <Section className="z-20 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={4}>
        <h2 id="success-stories" className="col-span-3 text-2xl lg:text-3xl">
          Success stories
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">We benefited from your A-Team experience and visionary approach in the mobile space. We got speed, quality and most of all a personalized approach.</p>
          <p className="mt-8">Dave, Managing Director</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            Tumba has been an integral part of the team. They combine a proven expertise in both engineering and product vision. On several occasions, Tumba went beyond the original project scope to ensure the delivery of projects within tight deadlines.
          </p>
          <p className="mt-8">Norman, Director of Product Development</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            Tumba enabled us to hit milestones quickly and securely. We came from a completely non-digital world and immersed ourselves as seen as leading in aspects in our industry in only 12 months. We couldn't have done that without Tumba.
          </p>
          <p className="mt-8">Richard, Commercial Director</p>
        </div>
      </Section>
      <Section className="z-20 pt-40 pb-24 lg:pb-40 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={5}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[0]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/services/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Services</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/expertise/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Expertise</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/team/" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">Team</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
      </Section>
      <Deco before decoType={1} gridRow={6} className="bg-primary-1" />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default IndustriesPage
